@tailwind base;
@import "./assets/base-styles.css";
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "inter";
  src: local('Inter-Regular'), url("./assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "bold";
  src: local('Inter-Bold'),url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "semibold";
  src: local('Inter-SemiBold'),url("./assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "pixel";
  src: local('Kubasta'), url(./assets/fonts/Kubasta.ttf) format('truetype');
}

@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    @apply bg-dark-blue text-off-white antialiased selection:bg-cherry-blossom font-inter
  }
  .nav-indicator {
    width: 4rem;
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240/var(--tw-bg-opacity));
  }
  @keyframes caret {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes caret {
    0%{opacity: 0;}
    100%{opacity: 1;}
  }
  .dialogWindow {
    border-image: url('./assets/dialogboxbg.png') 28 / 10px 10px 10px 10px stretch;
    border-width: 5px;
    border-style: solid;
    overflow: hidden;
    font-family: 'Pixel';
  }
  
  .dialogMessageCaret:after {
    content: "|";
    animation: caret 500ms linear infinite alternate;
  } 
}